import { css } from '@emotion/react'
import { type Amount } from '@orus.eu/amount'
import { calendarDateToString, PARIS, type CalendarDate } from '@orus.eu/calendar-date'
import { spacing, Text, useLanguage, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const PaymentInformationHeader = memo<{ paymentAmount: Amount; dueDate: CalendarDate }>(
  function PaymentInformationHeader({ paymentAmount, dueDate }) {
    const language = useLanguage()
    const translate = useTranslate()
    return (
      <div>
        <Text variant="subtitle2">{translate('subscription_funnel_checkout_payment_details')}</Text>
        <Text
          variant="body2"
          css={css`
            margin-top: ${spacing[20]};
          `}
        >
          {translate('subscription_funnel_checkout_payment_details_description', {
            amount: paymentAmount,
            date: calendarDateToString(dueDate, PARIS, 'DATE_SHORT', language),
          })}
        </Text>
      </div>
    )
  },
)
