import type { Amount, FinancialRate } from '@orus.eu/amount'
import type { PaymentSetupData, SignaturePreparationData } from '@orus.eu/backend/src/routers/subscriptions'
import type { CalendarDate } from '@orus.eu/calendar-date'
import {
  type AggregatedQuote,
  type Commitment,
  type ProductAttributes,
  type RiskCarrierProductType,
  type StaticFilesGroup,
} from '@orus.eu/dimensions'
import { ensureError } from '@orus.eu/error'
import { CheckoutLayout, useCrash, type CustomerData } from '@orus.eu/pharaoh'
import { memo, useEffect, useState, type FunctionComponent } from 'react'
import { trpc } from '../../../../client'
import { useTimedExplanations } from '../../../../lib/hooks/use-timed-explanations'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import CheckoutPaymentForm from '../../../organisms/payment-form/checkout-payment-form'
import WrongLoggedInUserMessage from '../wrong-logged-in-user-message'
import { FinalizingSubscriptionLoadingState } from './finalizing-subscription-loading-state'
import SharedSubscriptionContractElement from './shared-subscription-contract-element'
import { SubsequentSubscriptionsPaymentInformationBlock } from './subsequent-subscriptions-payment-information-block'

type SharedSubscriptionCheckoutReviewPageProps = {
  subscriptionId: string
  goBackToPreviousStep: (() => void) | undefined
  goBackToQuoteStep: () => void
  dueDate: CalendarDate
  paymentAmount: Amount
  generalTermsGroups: StaticFilesGroup[]
  startDate: CalendarDate
  quote: AggregatedQuote
  commitment: Commitment
  forbiddenMonthlyPayment: boolean
  discount: FinancialRate
  productAttributes: ProductAttributes[]
  yearlyCommitmentHasDiscount: boolean
  contractColumnWidth: string
  riskCarrierProducts: RiskCarrierProductType[]
  historyTakeoverPrice: Amount | undefined
  partnerApplicationFee: Amount | undefined
  className?: string
  customerData: CustomerData
}

const SharedSubscriptionCheckoutReviewPage: FunctionComponent<SharedSubscriptionCheckoutReviewPageProps> = memo(
  function SharedSubscriptionCheckoutReviewPage(props: SharedSubscriptionCheckoutReviewPageProps): JSX.Element {
    const [paymentSetupData, setPaymentSetupData] = useState<PaymentSetupData | undefined>()
    const [finalizingSubscription, setFinalizingSubscription] = useState(false)
    const {
      subscriptionId,
      goBackToPreviousStep,
      goBackToQuoteStep,
      dueDate,
      paymentAmount,
      generalTermsGroups,
      startDate,
      quote,
      commitment,
      forbiddenMonthlyPayment,
      discount,
      productAttributes,
      yearlyCommitmentHasDiscount,
      contractColumnWidth,
      riskCarrierProducts,
      className,
      customerData,
    } = props
    const crash = useCrash()
    const [signaturePreparationData, setSignaturePreparationData] = useState<
      SignaturePreparationData | 'wrong-user' | undefined
    >()

    useEffect(() => {
      let cancelled = false

      ;(async () => {
        const paymentSetupData = await trpc.subscriptions.prepareNewPaymentMethodSetup.mutate({
          subscriptionId,
        })
        if (cancelled) return

        setPaymentSetupData(paymentSetupData)

        const newSignaturePreparationData = await trpc.subscriptions.getSignaturePreparationData.query(subscriptionId)
        if (cancelled) return

        setSignaturePreparationData(newSignaturePreparationData)
      })().catch((err: unknown) => {
        if (cancelled) return

        crash({ type: 'unexpected-error', err: ensureError(err) })
      })

      return () => {
        cancelled = true
      }
    }, [crash, subscriptionId])

    if (!signaturePreparationData || !paymentSetupData) return <PrepareSignatureLoadingState />

    if (finalizingSubscription) return <FinalizingSubscriptionLoadingState />

    if (signaturePreparationData === 'wrong-user') {
      return <WrongLoggedInUserMessage />
    }

    const { token, signableFileId } = signaturePreparationData

    return (
      <CheckoutLayout
        className={className}
        contractColumnWidth={contractColumnWidth}
        goBack={goBackToPreviousStep}
        documents={
          <SharedSubscriptionContractElement
            subscriptionId={subscriptionId}
            contractFileId={signableFileId}
            generalTermsGroups={generalTermsGroups}
            startDate={startDate}
            quote={quote}
            commitment={commitment}
            forbiddenMonthlyPayment={forbiddenMonthlyPayment}
            discount={discount}
            productsAttributes={productAttributes}
            yearlyCommitmentHasDiscount={yearlyCommitmentHasDiscount}
            goBackToQuoteStep={goBackToQuoteStep}
            historyTakeoverPrice={props.historyTakeoverPrice}
            partnerApplicationFee={props.partnerApplicationFee}
          />
        }
        payment={
          paymentSetupData.paymentSetupRequired ? (
            <CheckoutPaymentForm
              subscriptionId={subscriptionId}
              token={token}
              stripeClientSecret={paymentSetupData.setupIntentClientSecret}
              dueDate={dueDate}
              paymentAmount={paymentAmount}
              generalTermsGroups={generalTermsGroups}
              riskCarrierProducts={riskCarrierProducts}
              customerData={customerData}
            />
          ) : (
            <SubsequentSubscriptionsPaymentInformationBlock
              subscriptionId={subscriptionId}
              token={token}
              dueDate={dueDate}
              paymentAmount={paymentAmount}
              generalTermsGroups={generalTermsGroups}
              riskCarrierProducts={riskCarrierProducts}
              paymentMethod={paymentSetupData.paymentMethod}
              onFinalizingSubscription={setFinalizingSubscription}
            />
          )
        }
      />
    )
  },
)
export default SharedSubscriptionCheckoutReviewPage

export const PrepareSignatureLoadingState = memo(function SigningLoadingState() {
  const currentExplanation = useTimedExplanations('finalizing_subscription_loading_explanations', 3000)

  return <GlobalLoadingState text={currentExplanation} />
})
