import { css } from '@emotion/react'
import { Avatar, Button, Text, useScreenType } from '@orus.eu/pharaoh'
import { memo, useCallback, type FunctionComponent } from 'react'
import SharedSubscriptionCheckoutErrorLayout from './shared-subscription-checkout-error-layout'

const SharedSubscriptionCheckoutSignatureErrorPage: FunctionComponent = memo(
  function SharedSubscriptionCheckoutPaymentErrorPage(): JSX.Element {
    const screenType = useScreenType()
    const reload = useCallback(() => {
      document.location.reload()
    }, [])

    return (
      <SharedSubscriptionCheckoutErrorLayout>
        <div>
          <Text variant="h2">La signature a échoué</Text>
        </div>
        <div>
          <Text>
            Nous venons de rencontrer un problème technique avec la signature de votre contrat 😔. Dans la majorité des
            cas cela se résout en cliquant sur “Revenir au paiement”. Si le problème persiste nous vous invitons à
            contacter l’équipe support pour aider à résoudre le problème à{' '}
            <a href="mailto:hello@orus.eu">hello@orus.eu</a>
          </Text>
        </div>
        <Button
          variant="primary"
          size="medium"
          avatarPosition="left"
          avatar={<Avatar icon="arrow-left-regular" />}
          onClick={reload}
          css={css`
            margin-top: ${screenType === 'mobile' ? 'auto' : '0px'};
          `}
        >
          Revenir au paiement
        </Button>
      </SharedSubscriptionCheckoutErrorLayout>
    )
  },
)
export default SharedSubscriptionCheckoutSignatureErrorPage
