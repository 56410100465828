import type { RiskCarrierProductType } from '../dimension/index.js'

export function getCommonClientAttributes(riskCarrierProducts?: RiskCarrierProductType[] | null): {
  client_category: string
  client_industry: string
  client_segmentation: string
} {
  if (!riskCarrierProducts) {
    return {
      client_category: '',
      client_industry: '',
      client_segmentation: '',
    }
  }

  return {
    client_category: getClientCategory(riskCarrierProducts),
    client_industry: getClientIndustry(riskCarrierProducts),
    client_segmentation: '',
  }
}

function getClientCategory(riskCarrierProducts: RiskCarrierProductType[]): string {
  return riskCarrierProducts.map((rcp) => rcp.split('-')[1]).join(',')
}

function getClientIndustry(riskCarrierProducts: RiskCarrierProductType[]): string {
  const retailerRiskCarrierProducts: RiskCarrierProductType[] = ['mrpw-rest-v1', 'mrpw-rest-v2', 'mrpw-retail-v1']

  return retailerRiskCarrierProducts.some((retailerRiskCarrierProduct) =>
    riskCarrierProducts.includes(retailerRiskCarrierProduct),
  )
    ? 'retailer'
    : '' //TODO - When SIRENE search done, create a real service for this
}
