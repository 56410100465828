import { css } from '@emotion/react'
import { type Amount } from '@orus.eu/amount'
import { type CalendarDate } from '@orus.eu/calendar-date'
import { type RiskCarrierProductType, type StaticFilesGroup } from '@orus.eu/dimensions'
import {
  IbanWizard,
  LegacyDialog,
  Spinner,
  TabBar,
  colors,
  spacing,
  useAsyncCallback,
  useTranslate,
  type CustomerData,
  type IbanData,
} from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { memo, useCallback, useState, type FormEvent, type FunctionComponent } from 'react'
import { trpc } from '../../../client'
import { useStripePromise } from '../../../lib/payment-provider-util'
import { stripeElementsZIndex } from '../../../lib/z-indexes'
import { ContractSignatureSection } from '../../pages/subscription/checkout/contract-signature-section'
import { PaymentInformationHeader } from '../../pages/subscription/checkout/payment-information-header'
import { useStripeOptions } from './stripe-options'

type CheckoutProps = {
  paymentAmount: Amount
  dueDate: CalendarDate
  token: string
  subscriptionId: string
  generalTermsGroups: StaticFilesGroup[]
  riskCarrierProducts: RiskCarrierProductType[]
  customerData: CustomerData
}

type CheckoutPaymentFormProps = {
  stripeClientSecret: string
} & CheckoutProps

const CheckoutPaymentForm: FunctionComponent<CheckoutPaymentFormProps> = memo(function CheckoutPaymentForm({
  stripeClientSecret,
  dueDate,
  paymentAmount,
  subscriptionId,
  token,
  generalTermsGroups,
  riskCarrierProducts,
  customerData,
}: CheckoutPaymentFormProps): JSX.Element {
  const stripePromise = useStripePromise()
  const stripeOptions = useStripeOptions(stripeClientSecret)

  return (
    <Elements options={stripeOptions} stripe={stripePromise}>
      <CheckoutPaymentElementWrapperForm
        dueDate={dueDate}
        paymentAmount={paymentAmount}
        subscriptionId={subscriptionId}
        token={token}
        generalTermsGroups={generalTermsGroups}
        riskCarrierProducts={riskCarrierProducts}
        customerData={customerData}
      />
    </Elements>
  )
})

export default CheckoutPaymentForm

const CheckoutPaymentElementWrapperForm: FunctionComponent<CheckoutProps> = memo(
  function CheckoutPaymentElementWrapperForm({
    dueDate,
    paymentAmount,
    subscriptionId,
    token,
    generalTermsGroups,
    riskCarrierProducts,
    customerData,
  }: CheckoutProps): JSX.Element {
    const translate = useTranslate()
    const stripe = useStripe()
    const elements = useElements()
    const [stripeErrorMessage, setStripeErrorMessage] = useState<string | null>(null)
    const [paymentMean, setPaymentMean] = useState<'sepa' | 'card'>('sepa')
    const [ibanData, setIbanData] = useState<IbanData | undefined>()

    const onPaymentMeanChange = useCallback((tabId: string) => {
      if (tabId !== 'sepa' && tabId !== 'card') return
      setPaymentMean(tabId)
    }, [])

    const handleSubmit = useAsyncCallback(
      async (event: FormEvent) => {
        event.preventDefault()

        const returnUrl = buildReturnUrl(token)

        if (paymentMean === 'sepa') {
          if (!ibanData) return
          const result = await trpc.subscriptions.receiveIban.mutate({ subscriptionId, ...ibanData })
          if (isFailure(result)) {
            setStripeErrorMessage('Une erreur est survenue. Votre IBAN est-il correct ?')
            return
          }

          window.location.href = returnUrl
          return
        }

        if (!stripe || !elements) {
          return
        }

        const confirmSetupPromise = stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: returnUrl,
          },
        })
        const { error } = await confirmSetupPromise

        if (error) {
          setStripeErrorMessage(error.message || 'Erreur inconnue')
        }
      },
      [elements, stripe, token, paymentMean, ibanData, subscriptionId],
    )

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <PaymentInformationHeader paymentAmount={paymentAmount} dueDate={dueDate} />
          <TabBar
            size="large"
            fullWidth
            tabs={{
              sepa: translate('payment_method_sepa_debit'),
              card: translate('payment_method_card'),
            }}
            selectedTabId={paymentMean}
            onTabChange={onPaymentMeanChange}
            css={css`
              margin-top: ${spacing[70]};
            `}
          />
          {paymentMean === 'card' ? (
            <div
              css={css`
                margin-top: ${spacing[70]};
                margin-bottom: ${spacing[70]};
                min-height: 345px;
                position: relative;
              `}
            >
              <Spinner
                size="50"
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                `}
              />
              <div
                css={css`
                  background-color: ${colors.white};
                  z-index: ${stripeElementsZIndex};
                  position: relative;
                `}
              >
                <PaymentElement />
              </div>
            </div>
          ) : (
            <div
              css={css`
                margin-top: ${spacing[70]};
                margin-bottom: ${spacing[70]};
              `}
            >
              <IbanWizard
                initialFirstName={customerData.firstName}
                initialLastName={customerData.lastName}
                initialEmail={customerData.email}
                initialAddress={customerData.address}
                initialPostalCode={customerData.postalCode}
                initialCity={customerData.city}
                onValidate={setIbanData}
              />
            </div>
          )}
          {paymentMean === 'card' || !!ibanData ? (
            <ContractSignatureSection
              subscriptionId={subscriptionId}
              generalTermsGroups={generalTermsGroups}
              riskCarrierProducts={riskCarrierProducts}
              paymentMeanFilled={paymentMean === 'card' || !!ibanData}
            />
          ) : null}
        </form>
        {stripeErrorMessage ? (
          <LegacyDialog title="Problème avec le paiement" onClose={() => setStripeErrorMessage(null)}>
            {stripeErrorMessage}
          </LegacyDialog>
        ) : (
          <></>
        )}
      </div>
    )
  },
)

function buildReturnUrl(token: string): string {
  const returnUrl = new URL(document.location.href)
  returnUrl.searchParams.set('validate', 'true')
  returnUrl.searchParams.set('token', token)
  return returnUrl.toString()
}
