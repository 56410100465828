import { useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { openChat } from '../../../lib/hubspot-util'
import { GenericProblemMessage } from '../../organisms/generic-problem-message'

const WrongLoggedInUserMessage = memo(function WrongLoggedInUserMessage() {
  const translate = useTranslate()
  return (
    <GenericProblemMessage
      title={translate('problem_wrong_user_title')}
      principalMessage={translate('problem_wrong_user_principal_message')}
      firstSubText={translate('problem_wrong_user_first_subtext')}
      secondSubText={translate('problem_wrong_user_second_subtext')}
      buttonText={translate('contact_customer_support')}
      onButtonClick={openChat}
      trackingId="contact_button"
    />
  )
})
export default WrongLoggedInUserMessage
