import { type Amount, type FinancialRate } from '@orus.eu/amount'
import type { CalendarDate } from '@orus.eu/calendar-date'
import {
  type AggregatedQuote,
  type Commitment,
  type PaymentRecurrence,
  type ProductAttributes,
  type RiskCarrierProductType,
  type StaticFilesGroup,
} from '@orus.eu/dimensions'
import type { CustomerData } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { CheckoutPagePhase } from './checkout-util'
import SharedSubscriptionCheckoutReviewPage from './shared-subscription-checkout-review-page'
import SharedSubscriptionCheckoutValidationPage from './shared-subscription-checkout-validation-page'

type CheckoutPageContentProps = {
  subscriptionId: string
  dueDate: CalendarDate
  generalTermsGroups: StaticFilesGroup[]
  quote: AggregatedQuote
  commitment: Commitment
  paymentRecurrence: PaymentRecurrence
  forbiddenMonthlyPayment: boolean
  discount: FinancialRate
  productsAttributes: ProductAttributes[]
  startDate: CalendarDate
  yearlyCommitmentHasDiscount: boolean
  phase: CheckoutPagePhase
  goBackToPreviousStep?: () => void
  goBackToQuoteStep: () => void
  contractColumnWidth: string
  riskCarrierProducts: RiskCarrierProductType[]
  historyTakeoverPrice: Amount | undefined
  partnerApplicationFee: Amount | undefined
  className?: string
  customerData: CustomerData
}
export const CheckoutPageContent = memo<CheckoutPageContentProps>(function CheckoutPageContent(props) {
  return props.phase.id === 'validation' ? (
    <SharedSubscriptionCheckoutValidationPage subscriptionId={props.subscriptionId} token={props.phase.token} />
  ) : (
    <SharedSubscriptionCheckoutReviewPage
      contractColumnWidth={props.contractColumnWidth}
      subscriptionId={props.subscriptionId}
      goBackToPreviousStep={props.goBackToPreviousStep}
      goBackToQuoteStep={props.goBackToQuoteStep}
      dueDate={props.dueDate}
      paymentAmount={props.quote.firstPaymentAmount}
      forbiddenMonthlyPayment={props.forbiddenMonthlyPayment}
      generalTermsGroups={props.generalTermsGroups}
      startDate={props.startDate}
      quote={props.quote}
      commitment={props.commitment}
      discount={props.discount}
      productAttributes={props.productsAttributes}
      yearlyCommitmentHasDiscount={props.yearlyCommitmentHasDiscount}
      riskCarrierProducts={props.riskCarrierProducts}
      historyTakeoverPrice={props.historyTakeoverPrice}
      partnerApplicationFee={props.partnerApplicationFee}
      className={props.className}
      customerData={props.customerData}
    />
  )
})
