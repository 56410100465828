import { css } from '@emotion/react'
import { spacing, useScreenType } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent, type ReactNode } from 'react'

type SharedSubscriptionCheckoutPaymentErrorLayoutProps = {
  children: ReactNode
}

const SharedSubscriptionCheckoutErrorLayout: FunctionComponent<SharedSubscriptionCheckoutPaymentErrorLayoutProps> =
  memo(function SharedSubscriptionCheckoutErrorLayout(
    props: SharedSubscriptionCheckoutPaymentErrorLayoutProps,
  ): JSX.Element {
    const { children } = props
    const screenType = useScreenType()
    return (
      <>
        {screenType === 'mobile' ? (
          <div
            css={css`
              height: 100%;
              display: flex;
              flex-direction: column;
              margin: ${spacing[60]};
              gap: ${spacing[70]};
              justify-content: center;
            `}
          >
            {children}
          </div>
        ) : (
          <div
            css={css`
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${spacing[70]};
                align-items: flex-start;
                max-width: 792px;
              `}
            >
              {children}
            </div>
          </div>
        )}
      </>
    )
  })
export default SharedSubscriptionCheckoutErrorLayout
